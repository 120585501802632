.tabbar {
  list-style-type: none;

  li {
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom-color: rgb(209, 213, 218);
    }
  }
}
