.userIcon {
  font-size: 32px;
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;

  &:hover {
    // box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    background-color: rgba(60, 64, 67, 0.08);
  }
}

.signin {
  white-space: nowrap;
}
